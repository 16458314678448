var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition-group',{attrs:{"name":"alertTransition","tag":"div"}},_vm._l((_vm.alerts),function(alert,identifier){return _c('div',{key:alert.identifier,ref:'alert_' + identifier,refInFor:true,style:('position: fixed; width: 100%; height: 100%; top: 0px; left: 0px; display: flex; flex-direction: column; justify-content: center; z-index: ' +
        String(100000000 + (alert.stackLevel ? alert.stackLevel : 0)) +
        '; background: transparent; background-color: rgba(0, 0, 0, 0.3);')},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","background-color":"white","width":"350px","margin-left":"auto","margin-right":"auto","text-align":"center","border-radius":"1em","max-height":"80%","filter":"drop-shadow(0px 0px 1em rgba(0, 0, 0, 0.2))","opacity":"1","font-size":"1.2em","overflow-wrap":"break-word","overflow-y":"auto","overflow-x":"hidden"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"padding":"1.5em 1.5em 0em 1.5em"}},[_c('div',{staticStyle:{"font-weight":"bold","white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(alert.title)+" ")]),_c('div',{staticStyle:{"font-weight":"plain","margin-top":"0.5em","white-space":"pre-wrap","user-select":"none"}},[_vm._v(" "+_vm._s(alert.message)+" ")])]),_c('div',{staticStyle:{"margin":"1em 0em 0em 0em","display":"flex","overflow-x":"hidden","overflow-y":"auto","user-select":"none"},style:(alert.actions.length == 2
                ? 'flex-direction: row; justify-content: space-evenly; flex-wrap: wrap;'
                : 'flex-direction: column;')},_vm._l((alert.actions),function(action,index){return _c('div',{key:index,staticClass:"alertAction",staticStyle:{"position":"relative","flex-grow":"1","max-width":"100%","min-width":"50%"},style:(index == alert.actions.length - 1 && alert.actions.length != 2
                  ? 'border-radius: 0 0 1em 1em;'
                  : '')},[(
                  action.type == 'normal' ||
                  action.type == 'cancel' ||
                  action.type == 'destructive'
                )?_c('div',[_c('div',{class:_vm.getActionStyleClassess(action.type, identifier, index),staticStyle:{"padding":"0.7em 0.7em 0.7em 0.7em","border-top":"0.1px solid rgba(0, 0, 0, 0.1)","cursor":"pointer","overflow-wrap":"break-word","text-align":"center","user-select":"none"},style:(alert.actions.length == 2
                      ? 'border-left: 0.1px solid rgba(0, 0, 0, 0.1); '
                      : ' '),on:{"click":function($event){return _vm.handlerProxy(identifier, index)}}},[_vm._v(" "+_vm._s(action.title)+" ")])]):_vm._e()])}),0)])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }